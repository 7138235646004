import { useForm } from "@wisr/common";
import { Button, ButtonRow, Field, Modal } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { analyticsSubmitEvent } from "../shared/analytics/analytics.utils";
import {
  FinancialMoodData,
  FinancialMoodResponse,
} from "../types/financial-mood";
import { creditUserGetAction } from "../user/credit/credit-user.actions";
import { useGetCreditUser } from "../user/credit/credit-user.hooks";
import { fetchPersonalisedRateEstimate } from "../widgets/borrowing/rate-estimate/personalised/personalised-rate-estimate-widget.thunk";
import {
  financialMoodGetAction,
  financialMoodToggleModalAction,
  financialMoodUpdateAction,
} from "./financial-mood.actions";
import {
  EMPTY_FINANCIAL_MOOD_FORM_DATA,
  FINANCIAL_MOOD_OPTIONS,
  MONEY_MOMENT_OPTIONS,
} from "./financial-mood.constants";
import { useGetFinancialMood } from "./financial-mood.hooks";
import financialMoodStyle from "./financial-mood.scss";
import { findMoments, findMood } from "./financial-mood.utils";

export const FinancialMoodModal: React.FC = () => {
  const [question, setQuestion] = React.useState(0);

  const dispatch = useDispatch();
  const creditProfileData = useGetCreditUser();
  const financialMood = useGetFinancialMood();
  const firstQuestion = question === 0;

  const { fieldAdapter, submitAdapter, formHasErrors, setValue } = useForm(
    EMPTY_FINANCIAL_MOOD_FORM_DATA
  );

  React.useEffect(() => {
    dispatch(creditUserGetAction());
    dispatch(financialMoodGetAction());
    dispatch(fetchPersonalisedRateEstimate());
  }, [dispatch, financialMood.isModalOpen]);

  React.useEffect(() => {
    if (
      financialMood.isModalOpen === true &&
      financialMood.financialMoodResponse?.financialMood
    ) {
      setValue("financialMood", findMood(financialMood).value);
      setValue(
        "moneyMoments",
        findMoments(financialMood).map((moment) => {
          return moment.value;
        })
      );
    }
  }, [financialMood, setValue]);

  const onSubmit = submitAdapter(
    (result: boolean, submittedFormData: FinancialMoodData) => {
      if (result) {
        const data: FinancialMoodResponse = {
          ...submittedFormData,
          versionNumber: creditProfileData?.versionNumber || 1,
        };
        dispatch(financialMoodToggleModalAction());
        dispatch(financialMoodUpdateAction(data));
        analyticsSubmitEvent("Financial mood");
        setQuestion(0);
      }
      return Promise.resolve();
    }
  );

  const questionSubtitles = [
    "How do you feel when it comes to your finances?",
    "What financial goals do you have in the next 12 months?",
  ];

  return (
    <>
      <style jsx>{financialMoodStyle}</style>
      <Modal
        size={firstQuestion ? "small" : "medium"}
        open={!!financialMood.isModalOpen}
        selfClose={false}
        titleText="Personalise your dashboard"
        tooltip={
          "This information is used to build a dashboard with content and tools relevant to your unique situation."
        }
        onClose={() => {
          dispatch(financialMoodToggleModalAction());
        }}
      >
        <div>
          <p>{questionSubtitles[question]}</p>
          <form noValidate onSubmit={onSubmit}>
            {firstQuestion ? (
              <Field
                label=""
                type="radio"
                placeholder="Placeholder..."
                variation="boxed"
                options={FINANCIAL_MOOD_OPTIONS}
                {...fieldAdapter({
                  name: "financialMood",
                  required: "Please select an option",
                })}
              />
            ) : (
              <Field
                label=""
                type="checkbox"
                placeholder="Placeholder..."
                options={MONEY_MOMENT_OPTIONS}
                variation="boxed"
                cols={2}
                {...fieldAdapter({
                  name: "moneyMoments",
                })}
              />
            )}
            <ButtonRow forForm>
              <Button button="primary" disabled={formHasErrors()}>
                {firstQuestion ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setQuestion(question + 1);
                    }}
                    disabled={formHasErrors()}
                  >
                    Next
                  </button>
                ) : (
                  <button type="submit">Done</button>
                )}
              </Button>
            </ButtonRow>
          </form>
        </div>
      </Modal>
    </>
  );
};
