import React from "react";
import { useGetNewActivityCount } from "../../score-credit-widget.hooks";
import ActivityCalloutStyles from "./activity-callout.scss";
import lightbulb from "../../../../../assets/images/lightbulb.svg";
import { ScoreProviders } from "../../../../../types/credit";
import { Link } from "gatsby";
import { analyticsClickEvent } from "../../../../../shared/analytics/analytics.utils";
import { BureauProviders } from "../../../../../types/analytics";
import { BUREAU_TYPE } from "../../../../../shared/analytics/analytics.consts";
import { SCORE_PROVIDER } from "../../score-credit-widget.consts";

interface ActivityCalloutProps {
  provider: ScoreProviders;
}

export const ActivityCallout: React.FC<ActivityCalloutProps> = ({
  provider,
}) => {
  const newActivityCount = useGetNewActivityCount();
  const capitalProvider: BureauProviders =
    provider === SCORE_PROVIDER.EQUIFAX
      ? BUREAU_TYPE.EQUIFAX
      : BUREAU_TYPE.EXPERIAN;
  const newActivityText =
    newActivityCount > 1
      ? `${newActivityCount} activities`
      : `${newActivityCount} activity`;

  if (newActivityCount < 1) {
    return null;
  }

  if (provider === "experian") {
    return (
      <div className="callout-container">
        <style jsx>{ActivityCalloutStyles}</style>
        <div className="callout-clear">
          <p className="extra-small-copy">
            Where&apos;s my {capitalProvider} insights?
            <br />
            Hold tight, we&apos;re working on it!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="callout-container">
      <style jsx>{ActivityCalloutStyles}</style>
      <div className="callout-content">
        <img src={lightbulb} />
        <p className="extra-small-copy">
          Your {capitalProvider} score changed due to:
          <br />
          <Link
            to={`/credit/insights/${provider}?insight=contributing-factors`}
            onClick={() =>
              analyticsClickEvent(`${capitalProvider} kcf activity`)
            }
          >
            {newActivityText}
          </Link>{" "}
          on your credit report
        </p>
      </div>
    </div>
  );
};
