import { FinancialMoodState } from "../types/financial-mood";
import {
  FINANCIAL_MOOD_OPTIONS,
  MONEY_MOMENT_OPTIONS,
} from "./financial-mood.constants";

export const findMood = (financialMood: FinancialMoodState) => {
  return FINANCIAL_MOOD_OPTIONS.find((mood) => {
    return financialMood.financialMoodResponse?.financialMood
      .toString()
      .includes(mood.value);
  });
};

export const findMoments = (financialMood: FinancialMoodState) => {
  return MONEY_MOMENT_OPTIONS.filter((moment) => {
    return financialMood.financialMoodResponse?.moneyMoments
      .map(String)
      .includes(moment.value);
  });
};
