import React from "react";
import Lottie from "react-lottie";
import loadingWheel from "./loading-wheel.json";
import loaderFullPageStyle from "./loader-full-page.scss";

interface LoaderFullPageProps {
  enabled: boolean;
}

export const LoaderFullPage: React.FC<LoaderFullPageProps> = ({ enabled }) => {
  if (enabled) {
    return (
      <div className="loader-background">
        <style jsx>{loaderFullPageStyle}</style>
        <div className="loader-wrapper">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingWheel,
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};
