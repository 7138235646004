import React from "react";
import equifaxLogo from "../../../../assets/images/equifax.png";
import experianLogo from "../../../../assets/images/experian.png";
import { GaugeGraphChartComponent } from "../../../../charts/gauge-graph/gauge-graph-chart.component";
import { ScoreProviders } from "../../../../types/credit";
import { WidgetComponent } from "../../../widget.component";
import { CreditScore } from "../../../../types/score-credit-widget";
import {
  equifaxBands,
  equifaxOneBands,
  experianBands,
  gaugeColours,
  SCORE_PROVIDER,
} from "../score-credit-widget.consts";
import { getSelectedScore } from "../score-credit-widget.utils";
import { ScoreCreditButtons } from "./score-credit-buttons/score-credit-buttons";
import { ScoreCreditMeta } from "./score-credit-meta/score-credit-meta";
import { ScoreCreditMovement } from "./score-credit-movement/score-credit-movement";
import scoreStyle from "./single-score-credit-widget.scss";
import { GaugeBands } from "../../../../types/graph";

interface CreditScoreProps {
  provider: ScoreProviders;
  creditScore: CreditScore;
  graphId?: string;
}

export const SingleScoreCreditWidget: React.FC<CreditScoreProps> = ({
  provider,
  creditScore,
  graphId,
}) => {
  const selectedScore = getSelectedScore(creditScore);
  const isEquifax = provider === SCORE_PROVIDER.EQUIFAX;
  const hasOneScore =
    creditScore.oneScoreComprehensive &&
    creditScore.oneScoreComprehensive !== 0;
  const selectedEquifaxBands = hasOneScore ? equifaxOneBands : equifaxBands;
  const bands: GaugeBands[] = isEquifax ? selectedEquifaxBands : experianBands;
  const noScore = creditScore.creditScoreDisplay !== "Has credit score";
  const classNames = noScore ? "no-score-container" : "";

  return (
    <WidgetComponent
      widgetName="credit-score-single"
      widgetType="credit"
      classes={classNames}
    >
      <div className="widget-container">
        <style jsx>{scoreStyle}</style>

        <div className="widget-data">
          <div
            className={`widget-graph ${provider}-provider`}
            id={graphId ? graphId : `${provider}-graph`}
          >
            <div className="provider-logo-wrapper">
              <img
                src={
                  provider === SCORE_PROVIDER.EQUIFAX
                    ? equifaxLogo
                    : experianLogo
                }
                alt={`${provider} logo`}
              />
            </div>
            <GaugeGraphChartComponent
              gaugeData={{
                gaugeData: noScore ? "?" : selectedScore,
                gaugeLimit: creditScore.maxScore,
                gaugeLabel: provider,
                gaugeDialColour: gaugeColours[provider],
                customLabel: creditScore.creditScoreDisplay,
              }}
              gaugeBands={bands}
              id={noScore ? "" : `graph-${provider}`}
            />
            {!noScore && <ScoreCreditMovement creditScore={creditScore} />}
          </div>
          <div className={`widget-content ${noScore ? "no-score" : ""}`}>
            <ScoreCreditMeta
              noScore={noScore}
              provider={provider}
              scores={creditScore}
            />
          </div>
        </div>
        <ScoreCreditButtons noScore={noScore} provider={provider} />
      </div>
    </WidgetComponent>
  );
};
