import { capitalise } from "@wisr/common";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useDispatch } from "react-redux";
import { analyticsClickEvent } from "../../../../shared/analytics/analytics.utils";
import { BadgeComponent } from "../../../../shared/badge/badge.component";
import { NavLink } from "../../../shared/nav-link/nav-link.component";
import { Title } from "../../../shared/title/title.component";
import { WidgetComponent } from "../../../widget.component";
import { useGetNewActivityCount } from "../score-credit-widget.hooks";
import { CreditScore } from "../../../../types/score-credit-widget";
import { SCORE_PROVIDER } from "../score-credit-widget.consts";
import { CompactSingleScoreCreditWidget } from "../single/compact/compact-single-score-credit-widget";
import scoreStyle from "./compact-score-credit-widget.scss";
import { StatusCallout } from "@wisr/react-ui";
import { getVerifiedMobileNumbers } from "../../../../forms/credit-profile/mobile-verification/mobile-verification.utils";
import { useGetWisrProfile } from "../../../../redux/wisr-profile/wisr-profile.hooks";
import { wisrProfileGetAction } from "../../../../redux/wisr-profile/wisr-profile.actions";
import { calloutMessageWithLink } from "../../../../credit/verify-mobile/verify-mobile.consts";

interface CreditScoreProps {
  providers?: CreditScore[];
  widgetId?: string;
}

export const CompactScoreCreditWidget: React.FC<CreditScoreProps> = ({
  providers = [],
  widgetId,
}) => {
  const noScores = providers.every(
    (provider) => provider.creditScoreDisplay !== "Has credit score"
  );
  const newActivityCount = useGetNewActivityCount();
  const { showKcfDetails } = useFlags();
  const wisrProfile = useGetWisrProfile();
  const verifiedMobileNumbers = getVerifiedMobileNumbers(wisrProfile);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (wisrProfile === undefined) {
      dispatch(wisrProfileGetAction());
    }
  }, [dispatch, wisrProfile]);

  return (
    <WidgetComponent widgetName="credit-score-compact" widgetType="credit">
      <div className="widget-container" id={widgetId}>
        <style jsx>{scoreStyle}</style>
        <Title>
          <h1>Credit Scores</h1>
        </Title>
        <div className="callout-wrapper">
          {verifiedMobileNumbers.length < 1 ? (
            <StatusCallout type="warning" message={calloutMessageWithLink} />
          ) : null}
        </div>
        <div className="widget-data">
          <div className="widget-content">
            {providers.map((summary, summaryIndex) => {
              const providerName = capitalise(summary.provider);
              return (
                <div
                  className={`score-container ${summary.provider.toLowerCase()}-container`}
                  key={`summary-${summaryIndex}`}
                >
                  <div className="title-wrapper">
                    <p className={`provider-title`}>{providerName}</p>
                    {!!newActivityCount &&
                      showKcfDetails &&
                      summary.provider === SCORE_PROVIDER.EQUIFAX && (
                        <BadgeComponent badge="alert">
                          New activity
                        </BadgeComponent>
                      )}
                  </div>
                  <CompactSingleScoreCreditWidget summary={summary} />
                </div>
              );
            })}
          </div>
        </div>
        <NavLink
          className="view-scores-link"
          to="/credit"
          size="medium"
          onClick={() => analyticsClickEvent(`View scores`)}
        >
          {noScores ? "See more" : "View scores"}
        </NavLink>
      </div>
    </WidgetComponent>
  );
};
