import { useSelector } from "react-redux";
import { PartnerReferralState } from "../../types/partner-referral";
import { AppStore } from "../../store";
import { selectpartnerReferralState } from "./partner-referral.slice";

export const useGetPartnerReferral = () => {
  return useSelector<AppStore, PartnerReferralState>(
    selectpartnerReferralState
  );
};
