import { capitalise } from "@wisr/common";
import { Button, ButtonRow } from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import { analyticsClickEvent } from "../../../../../shared/analytics/analytics.utils";
import { ScoreProviders } from "../../../../../types/credit";
import { useIdChecksSuccessful } from "../../score-credit-widget.hooks";

interface ScoreCreditButtonsProps {
  noScore: boolean;
  provider: ScoreProviders;
}

export const ScoreCreditButtons = ({
  noScore,
  provider,
}: ScoreCreditButtonsProps) => {
  const capitalProvider = capitalise(provider);
  const isIdMatrixPass = useIdChecksSuccessful();
  if (noScore) {
    return isIdMatrixPass ? (
      <ButtonRow>
        <Button button="tertiary" fullWidth>
          <Link
            to={`/credit/faq`}
            onClick={() => analyticsClickEvent("Learn more")}
          >
            Learn more
          </Link>
        </Button>
      </ButtonRow>
    ) : (
      <ButtonRow>
        <Button button="secondary" fullWidth>
          <Link
            to={`/credit/my-account`}
            onClick={() => analyticsClickEvent(`Review my information`)}
          >
            Review my information
          </Link>
        </Button>
      </ButtonRow>
    );
  }
  return (
    <ButtonRow>
      <Button button="secondary">
        <Link
          to={`/credit/insights/${provider}`}
          onClick={() => analyticsClickEvent(`View ${provider} profile`)}
        >
          View {capitalProvider} insights
        </Link>
      </Button>
    </ButtonRow>
  );
};
