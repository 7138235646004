import React from "react";
import {
  convertTimeZone,
  stringToDate,
} from "../../../../../shared/date.utils";
import { CreditScore } from "../../../../../types/score-credit-widget";
import { getLatestDate, getScoreState } from "../../score-credit-widget.utils";
import { ActivityCallout } from "../activity-callout/activity-callout";
import scoreCreditMovementStyle from "./score-credit-movement.scss";

interface ScoreCreditMetaContentProps {
  creditScore: CreditScore;
}

export const ScoreCreditMovement = ({
  creditScore,
}: ScoreCreditMetaContentProps) => {
  const validUpdate = creditScore.runDetails.length > 0;
  const lastUpdate: string | Date = convertTimeZone(
    getLatestDate(creditScore.runDetails, true)
  );
  const nextUpdate: string | Date = convertTimeZone(
    getLatestDate(creditScore.runDetails)
  );
  const provider = creditScore.provider;

  return (
    <>
      <style jsx>{scoreCreditMovementStyle}</style>
      <div
        className={`score-movement ${getScoreState(creditScore.deltaScore)}`}
        id={`credit-score-points-${provider}`}
      >
        {creditScore.deltaScore === 0
          ? "No change"
          : `${creditScore.deltaScore} points`}
      </div>
      <ActivityCallout provider={creditScore.provider} />
      <div className="subtitle">
        <span id={`credit-score-last-update-${provider}`}>
          Last {validUpdate && stringToDate(lastUpdate, "two-digits")}{" "}
        </span>
        <span className="divider">&bull;</span>{" "}
        <span id={`${provider}-credit-score-next-update`}>
          Updates {stringToDate(nextUpdate, "two-digits")}
        </span>
      </div>
    </>
  );
};
