import { Link } from "gatsby";
import React from "react";

export const calloutMessage = (
  <>
    Updates to Credit Scores have been paused for security reasons. Verify your
    mobile number to use this feature.
  </>
);

export const calloutMessageWithLink = (
  <>
    {calloutMessage}
    <br />
    <br />
    <Link to="/credit">Verify mobile number</Link>
  </>
);
