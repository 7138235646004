import { Icon } from "@wisr/react-ui";
import React from "react";

export const referralCopy = (
  <>
    <p>You are not eligible for a Wisr loan at this time.</p>
    <p>
      However, we&apos;ve teamed up with Driva, an award-winning Australian loan
      broker who have <strong>30+ lenders</strong> on their platform.
    </p>
    <p>
      Would you like to get a rate estimate with Driva to see if they can help
      you find a match?
    </p>
    <p>This won&apos;t impact your credit score.</p>
    <ul>
      <li>
        By clicking “Get started with Driva”, you are giving Driva permission to
        contact you and access your loan details.
      </li>
      <li>If your referral is successful, Wisr will earn a commission.</li>
    </ul>
  </>
);

export const referralTitle = <>Looking for a loan?</>;

export const alternateReferralCopy = (
  <>
    <p>
      You&apos;re not eligible for a Wisr loan right now, but perhaps our
      friends at Driva can help.
    </p>
    <p>
      Driva is an award-winning Australian loan broker who talks to over 30
      different lenders and can help you get the loan you need.
    </p>
  </>
);

export const alternateReferralList = (
  <>
    <div className="list-item">
      <Icon name="status-tick" size="sm" legacy={false} />
      <p>More than 30+ lenders</p>
    </div>
    <div className="list-item">
      <Icon name="status-tick" size="sm" legacy={false} />
      <p>No impact to your credit score</p>
    </div>
    <div className="list-item">
      <Icon name="status-tick" size="sm" legacy={false} />
      <p>Personalised rate in 60 secs</p>
    </div>
  </>
);

export const buttonSubtext = <>This won&apos;t impact your credit scores</>;

export const referralDisclaimer = (
  <>
    By continuing you&apos;re giving permission to share your details to Driva.
    If Driva helps you get a loan, Wisr will receive a commission.
  </>
);

export const SCORES_REFERRAL_PRODUCT = "Credit Scores";
