import React from "react";
import car from "../../../../assets/images/widget-icons/car.svg";
import consolidation from "../../../../assets/images/widget-icons/consolidation.svg";
import creditCard from "../../../../assets/images/widget-icons/credit-card.svg";
import house from "../../../../assets/images/contributing-factors/residential.svg";
import paintbrush from "../../../../assets/images/widget-icons/paintbrush.svg";
import ring from "../../../../assets/images/widget-icons/ring.svg";
import smartPart from "../../../../assets/images/widget-icons/smart-part.svg";
import star from "../../../../assets/images/contributing-factors/closed-cards.svg";
import suitcase from "../../../../assets/images/widget-icons/suitcase.svg";
import thought from "../../../../assets/images/widget-icons/thought.svg";

export const getWidgetIcon = (pictogramName?: string) => {
  switch ((pictogramName ?? "").toLowerCase()) {
    case "thought":
      return <img src={thought} />;
    case "car":
      return <img src={car} />;
    case "consolidation":
      return <img src={consolidation} />;
    case "credit-card":
      return <img src={creditCard} />;
    case "suitcase":
      return <img src={suitcase} />;
    case "paintbrush":
    case "home-improvements":
      return <img src={paintbrush} />;
    case "ring":
      return <img src={ring} />;
    case "house":
      return <img src={house} />;
    case "star":
      return <img src={star} />;
    case "brain":
      return <img src={smartPart} />;
    default:
      return <img src={smartPart} />;
  }
};

export const getLoanPurpose = (moment?: string) => {
  switch (moment) {
    case "Consolidation":
    case "Credit card":
    case "KCF Refinance":
      return "&Purpose=Consolidation";
    case "Vehicle":
      return "&Purpose=Car";
    case "Holiday":
      return "&Purpose=Holiday";
    case "Renovation":
    case "KCF Renovation":
      return "&Purpose=HomeImprovements";
    case "Wedding":
      return "&Purpose=Other";
    default:
      return "";
  }
};
