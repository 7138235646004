import React from "react";
import { WidgetComponent } from "../../widget.component";
import ctaStyle from "./cta-widget.scss";

interface CTAWidgetProps {
  type: "credit" | "loan" | "primary";
}

export const CTAWidgetComponent: React.FC<CTAWidgetProps> = ({
  children,
  type,
}) => {
  return (
    <WidgetComponent widgetType="other" widgetName={type}>
      <style jsx>{ctaStyle}</style>
      <div className={`cta-widget ${type}`}>{children}</div>
    </WidgetComponent>
  );
};
