import { capitalise } from "@wisr/common";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { ScoreProviders } from "../../../../../types/credit";
import { HighlightComponent } from "../../../../shared/highlight/highlight.component";
import { formatInsightsData } from "../../../insights/insights-credit.utils";
import { useIdChecksSuccessful } from "../../score-credit-widget.hooks";
import { CreditScore } from "../../../../../types/score-credit-widget";

interface ScoreCreditMetaProps {
  noScore: boolean;
  provider: ScoreProviders;
  scores: CreditScore | undefined;
}

export const ScoreCreditMeta = ({
  noScore,
  provider,
  scores,
}: ScoreCreditMetaProps) => {
  const { showBillsPaidOnTime } = useFlags();
  const { totalEnquiries, paymentsOnTime, totalAccounts, fileAge } =
    formatInsightsData(scores);
  const capitalProvider = capitalise(provider);
  const isIdMatrixPass = useIdChecksSuccessful();

  if (noScore) {
    return isIdMatrixPass ? (
      <p className="description">
        Your information doesn&apos;t appear to be on record with{" "}
        {capitalProvider}. This may mean you don&apos;t have an existing credit
        profile with this credit Bureau.
      </p>
    ) : (
      <p className="description">
        Your information isn&apos;t matching any profiles with the credit
        Bureau. Check that the profile information provided is accurate and try
        again.
      </p>
    );
  }
  return (
    <HighlightComponent
      variation="boxed"
      list={[
        {
          title: totalEnquiries.toString(),
          description: "Credit enquiries",
          type: "small",
          id: `${provider}-credit-enquiries`,
        },
        {
          title: totalAccounts.toString(),
          description: "Total accounts",
          type: "small",
          id: `${provider}-total-accounts`,
        },
        {
          title: showBillsPaidOnTime ? paymentsOnTime : "n/a",
          description: "Payments on time",
          type: "small",
          id: `${provider}-payments-on-time`,
        },
        {
          title: fileAge,
          description: "Credit file age",
          type: "small",
          id: `${provider}-file-age`,
        },
      ]}
    />
  );
};
