import { capitalise, isDefined, useLoadingStatus } from "@wisr/common";
import { Card, Tabs } from "@wisr/react-ui";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { analyticsLoadCreditWidgetEvent } from "../../../shared/analytics/analytics.utils";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { WidgetComponent } from "../../widget.component";
import { CompactScoreCreditWidget } from "./compact/compact-score-credit-widget";
import { scoreCreditGetAction } from "./score-credit-widget.actions";
import { GETSCORE_CREDIT_LOADING } from "./score-credit-widget.epic";
import {
  useGetNewActivityCount,
  useGetScores,
  useIdChecksSuccessful,
  useScoreProcessing,
} from "./score-credit-widget.hooks";
import scoreStyle from "./score-credit-widget.scss";
import {
  getInsightsByProvider,
  getScoresEventData,
  getSingleScoreEventData,
} from "./score-credit-widget.utils";
import { SingleScoreCreditWidget } from "./single/single-score-credit-widget";
import { SkeletonLoaderWidget } from "./skeleton-loader-widget";

interface ScoreWidgetProps {
  variation?: "home" | "" | "compact";
}

export const ScoreCreditWidgetComponent: React.FC<ScoreWidgetProps> = ({
  variation = "",
}) => {
  const [scoreLoading, loadingScoresError] = useLoadingStatus(
    GETSCORE_CREDIT_LOADING
  );

  const dispatch = useDispatch();

  const creditScores = useGetScores();
  const newActivityCount = useGetNewActivityCount();
  const isIdMatrixPass = useIdChecksSuccessful();
  const scoreProcessing = useScoreProcessing(creditScores);
  const SCORE_DELAY = 5000;
  const ANALYTICS_DELAY = 2000; //Component will be rendered multiple times on initial load, delay is to avoid duplicate events
  const componentIsReady = !scoreLoading && isDefined(creditScores);

  const dispatchCreditActions = useCallback(() => {
    if (scoreProcessing.attempt < 3) {
      setTimeout(() => {
        /* istanbul ignore else */
        if (scoreProcessing.status) {
          dispatch(scoreCreditGetAction());
        }
      }, SCORE_DELAY);
    }
  }, [dispatch, scoreProcessing]);

  React.useEffect(() => {
    const scoresEventTimeout = setTimeout(() => {
      if (creditScores?.creditScoreSummaryList) {
        analyticsLoadCreditWidgetEvent(
          "Credit scores",
          getScoresEventData(
            creditScores.creditScoreSummaryList,
            !!isIdMatrixPass,
            !!newActivityCount
          )
        );
      }
      if (creditScores?.creditScoreSummaryList && variation !== "compact") {
        creditScores.creditScoreSummaryList.forEach((score) => {
          const insights = getInsightsByProvider(
            creditScores.creditScoreSummaryList,
            score.provider
          );
          if (isDefined(insights)) {
            analyticsLoadCreditWidgetEvent(
              "Credit score insights",
              getSingleScoreEventData(score.provider, score, newActivityCount)
            );
          }
        });
      }
    }, ANALYTICS_DELAY);

    return () => {
      clearTimeout(scoresEventTimeout);
    };
  }, [creditScores, isIdMatrixPass, newActivityCount, variation]);

  React.useEffect(() => {
    if (scoreProcessing.status) {
      dispatchCreditActions();
    }
  }, [scoreProcessing, dispatchCreditActions]);

  if (loadingScoresError) {
    return (
      <WidgetComponent widgetName="credit-score-error" widgetType="credit">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  if (!componentIsReady) {
    return <SkeletonLoaderWidget variation={variation} />;
  }

  if (variation === "compact") {
    return (
      <CompactScoreCreditWidget
        providers={creditScores.creditScoreSummaryList}
      />
    );
  }

  return (
    <>
      <style jsx>{scoreStyle}</style>
      <div className="mobile-only">
        <Card>
          <Tabs
            tabTitles={
              creditScores.creditScoreSummaryList?.map((score) =>
                capitalise(score.provider)
              ) || []
            }
          >
            {creditScores.creditScoreSummaryList?.map((score, index) => {
              return (
                <SingleScoreCreditWidget
                  provider={score.provider}
                  creditScore={score}
                  graphId={`${score.provider}-graph-mobile`}
                  key={index}
                />
              );
            })}
          </Tabs>
        </Card>
      </div>
      <div className="desktop-only">
        <div className={`grid ${variation || ""}`}>
          {creditScores.creditScoreSummaryList?.map((score, index) => {
            return (
              <div className="item" key={index}>
                <SingleScoreCreditWidget
                  provider={score.provider}
                  creditScore={score}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
