import React from "react";
import { WidgetComponent } from "../../widget.component";
import {
  SCORES_REFERRAL_PRODUCT,
  alternateReferralCopy,
  referralDisclaimer,
  referralTitle,
  referralCopy,
  alternateReferralList,
} from "./partner-referral-widget.consts";
import driva from "../../../assets/images/widget-icons/driva.svg";
import localStyle from "./partner-referral-widget.scss";
import { Button } from "@wisr/react-ui";
import { submitForPartnerReferral } from "../../../redux/partner-referral/partner-referral-widget.thunk";
import { useDispatch } from "react-redux";
import { useGetWisrProfile } from "../../../redux/wisr-profile/wisr-profile.hooks";
import { FetchStatus } from "../../../shared/redux.consts";
import { useGetPartnerReferral } from "../../../redux/partner-referral/partner-referral-widget.hooks";
import { LoaderFullPage } from "../../../shared/loader-full-page/loader-full-page";
import {
  amplitudeHelper,
  gtmSendEvent,
} from "../../../shared/analytics/analytics.utils";
import { Events } from "../../../shared/analytics/analytics.consts";
import { useFlags } from "launchdarkly-react-client-sdk";

export const PartnerReferralWidget: React.FC = () => {
  const { drivaNewDesignEnabled } = useFlags();
  const wisrProfile = useGetWisrProfile();
  const dispatch = useDispatch();
  const partnerReferralLoading =
    useGetPartnerReferral().status === FetchStatus.LOADING;

  const onSubmit = () => {
    if (wisrProfile?.wisrCustomerId) {
      dispatch(
        submitForPartnerReferral({
          application: "credit-profile",
          wisrCustomerId: wisrProfile.wisrCustomerId,
        })
      );
    }
  };

  React.useEffect(() => {
    amplitudeHelper.sendEvent(
      Events.PRESENTED_DRIVA,
      {
        "Product offer": SCORES_REFERRAL_PRODUCT,
      },
      {
        "Driva design": drivaNewDesignEnabled ? "third" : "old",
      }
    );
    gtmSendEvent(Events.PRESENTED_DRIVA, {
      productOffer: SCORES_REFERRAL_PRODUCT,
    });
  }, [drivaNewDesignEnabled]);

  return (
    <WidgetComponent widgetName="partner-referral" widgetType="other">
      <style jsx>{localStyle}</style>
      {drivaNewDesignEnabled ? (
        <div className="referral-widget-alternate">
          <img src={driva} className="widget-image" />
          <h2 className="widget-title">{referralTitle}</h2>
          <div className="widget-text">{alternateReferralCopy}</div>
          <div className="list">{alternateReferralList}</div>
          <Button theme="navy" fullWidth={true}>
            <button type="button" onClick={onSubmit}>
              See my options with Driva
            </button>
          </Button>
          <p className="widget-disclaimer">{referralDisclaimer}</p>
        </div>
      ) : (
        <div className="referral-widget">
          <img src={driva} className="widget-image" />
          <h2 className="widget-title">{referralTitle}</h2>
          <div className="widget-text">{referralCopy}</div>
          <Button theme="navy" fullWidth={true}>
            <button type="button" onClick={onSubmit}>
              Get started with Driva
            </button>
          </Button>
        </div>
      )}
      <LoaderFullPage enabled={partnerReferralLoading} />
    </WidgetComponent>
  );
};
