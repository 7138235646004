import React from "react";
import titleStyle from "./title.scss";

export const Title: React.FunctionComponent = ({ children }) => {
  return (
    <div className="widget-title">
      <style jsx>{titleStyle}</style>
      {children}
    </div>
  );
};
