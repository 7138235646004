import React from "react";
import {
  convertTimeZone,
  stringToDate,
} from "../../../../../shared/date.utils";
import { CreditScore } from "../../../../../types/score-credit-widget";
import { SCORE_DESCRIPTION } from "../../score-credit-widget.consts";
import {
  getLatestDate,
  getScoreState,
  getSelectedScore,
} from "../../score-credit-widget.utils";
import scoreStyle from "./compact-single-score-credit-widget.scss";

interface CreditScoreProps {
  summary: CreditScore;
}

export const CompactSingleScoreCreditWidget: React.FC<CreditScoreProps> = ({
  summary,
}) => {
  const validUpdate = summary.runDetails.length > 0;

  if (summary.creditScoreDisplay !== "Has credit score") {
    return (
      <div className="score-summary">
        <style jsx>{scoreStyle}</style>
        <div className={`${summary.provider}-container`}>
          <div className="score-container">
            <div className="score-points">{"N/A"}</div>
            <div className={`score-delta no-score`}>
              {summary.idCheckSuccessful
                ? SCORE_DESCRIPTION.NORECORD
                : SCORE_DESCRIPTION.NOMATCH}
            </div>
          </div>
          <p className="updated-date">
            Updated{" "}
            {validUpdate &&
              stringToDate(
                convertTimeZone(getLatestDate(summary.runDetails, true)),
                "two-digits"
              )}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="score-summary">
      <style jsx>{scoreStyle}</style>
      <div className={`${summary.provider}-container`}>
        <div className="score-container">
          <div className="score-points">{getSelectedScore(summary)}</div>
          <div className={`score-delta ${getScoreState(summary.deltaScore)}`}>
            {summary.deltaScore !== 0 ? `${summary.deltaScore}` : "No change"}
          </div>
        </div>
        <p className="updated-date">
          Updated{" "}
          {validUpdate &&
            stringToDate(
              convertTimeZone(getLatestDate(summary.runDetails, true)),
              "two-digits"
            )}
        </p>
      </div>
    </div>
  );
};
